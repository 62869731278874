import React from 'react';
import './Newsletter.css';

function Newsletter(props) {
    const {
        setTabIndex
    } = props;

    return (
        <>
            <h2>Newsletter</h2>
            <p>
                Our newsletter is written, edited, and published by Orthodox children, passionate about their faith and the future of Orthodoxy.
                Orthodox youth of all ages are able to submit articles, opinion pieces, and short stories.
                Our continual goal is to have contributions from Orthodox youth of all ages, ethnicities, and regions, from which our editorial board choose several to publish.
                As much as possible, the theme of the submissions should be the Orthodox faith, from the beauty and timelessness of our faith, to contemporary issues facing the Orthodox world, and everything in between.
            </p>

            <h2>Purchase our Newsletter</h2>
            <p>
                Our monthly newsletter is available to all for a nominal fee of $1.00 to cover our printing and other startup costs.
                We will send out a digital copy of the newsletter to all emails that have <span className='plink' onClick={() => setTabIndex(4)}>donated</span> any amount through our website during the month.
                If you prefer a physical copy of our newsletter, please <span className='plink' onClick={() => setTabIndex(3)}>contact</span> us.
            </p>

            <h2>Monthly Issues</h2>
            <h3>June Issue</h3>
            <p>
                It is truly providential that our first issue coincides with the great feast of Pentecost, on which the apostles received the gift of the Holy Spirit and, guided by it, preached Christ to the people of all nations.
                The teaching of the apostles was handed down to each subsequent generation of correctly-believing (Orthodox) Christians as a timeless treasure.
                And since the days of the apostles, every generation of Orthodox Christians has passed on the greatest gift, the true Christian faith and apostolic tradition, to their children who have then become the next generation of Orthodox Christians.
                Historically, the primary keepers and defenders of the faith have been the faithful people of God, by enduring persecutions, combating heresies, and in general, striving to preserve the purity of the faith of the fathers. Orthodox Christian youth are a most vital link keeping our faith alive in today’s difficult times.
                A united Orthodox youth that is passionate, committed, and unashamed to witness to their faith will first inspire Orthodox of all ages to a more complete living of the faith, and will also serve to bring the evangelical message of Orthodoxy to non-Orthodox.
            </p>
        </>
    );
}

export default Newsletter;