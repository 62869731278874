const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;

export const validateEmail = email => (email && emailRegex.test(email));

export const recaptchaToken = () => {
    const grecaptcha = window.grecaptcha;

    return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
            resolve(grecaptcha.execute(
                '6Lf2LQAVAAAAABggUifjn1jQQdzrDrXL5rciXiuw',
                { action: 'submit' }
            ));
        });
    });
};