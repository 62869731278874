import React from 'react';
import './Events.css';

function Events(props) {

    return (
        <>
            <h2>Upcoming Events</h2>
            <p>
                We are happy to anounce our Spring 2021 FOYO Art Contest! Any youth from the age 4-18 are able to send any art piece made by the contestant without anyone’s help. Deadline for entries - April 18, 2021.
            </p>
            <p>
                Entries may be sent to our email: <a href = "mailto:futureoforthodoxy@gmail.com">futureoforthodoxy@gmail.com</a>, or physically to
            </p>
            <p>
                F.O.Y.O.<br/>
                23411 E. Orville St.<br/>
                Florence, AZ 85132
            </p>
        </>
    );
}

export default Events;