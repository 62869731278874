import React, { useState, useMemo } from 'react';
import './Contact.css';
import axios from 'axios';
import backend from '../../backend.json';
import { validateEmail, recaptchaToken } from '../../helpers';

const contactUrl = backend.contactBaseUrl + backend.emailPath;

function Contact(props) {
    const {
        setTabIndex
    } = props;

    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);
    const [inFlight, setInFlight] = useState(false);

    const validFieldAndReason = useMemo(() => validateEmail(email), [email]) && reason;

    return (
        <>
            <h2>Contact Us</h2>
            <p>
                Please enter your email address, a reason for contact, as well as a few sentences below.
                We will email you a copy of your request automatically.
                And one of our members will reach out to you as soon as possible.
                We look forward to hearing from you!
            </p>

            <p>Email (Required)</p>
            <input onChange={event => setEmail(event.target.value)} value={email} placeholder={'Email*'} />

            <p>Contact Reason (Required)</p>
            <input onChange={event => setReason(event.target.value)} value={reason} placeholder={'Contact Reason*'} />

            <p>Description</p>
            <textarea onChange={event => setDescription(event.target.value)} value={description} placeholder={'Please enter a description'} />

            <br />

            {error ? <p>An error occurred while contacting Future of Orthodoxy</p> : <></>}

            {validFieldAndReason ? <button
                className='g-recaptcha'
                disabled={inFlight}
                onClick={() => {
                    setError(false);
                    setInFlight(true);
                    recaptchaToken()
                        .then(token => {
                            axios.post(contactUrl, { token, email, reason, description })
                                .then(data => {
                                    setEmail('');
                                    setReason('');
                                    setDescription('');
                                    setError(false);
                                    setTabIndex(0);
                                })
                                .catch(err => {
                                    setError(true);
                                    console.error(err);
                                })
                                .finally(() => {
                                    setInFlight(false);
                                })
                        });
                }}
            >
                Submit
            </button> : <></>}
        </>
    );
}

export default Contact;