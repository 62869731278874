import React from 'react';
import './Donate.css';

function Donate(props) {

    return (
        <>
            <h2>Donate</h2>
            <p>
                The youth leaders of Future of Orthodoxy keep a very limited budget for several expenses.
                These include printing costs for our newsletter, hosting fees for our website, and other startup costs.
                Thank you for helping us with any amount you may be able to give.
            </p>

            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="L6XFWDYFA537Y" />
                <input type="hidden" name="item_name" value="Future of Orthodoxy Youth Organization" />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </>
    );
}

export default Donate;