import React from 'react';
import './App.css';
import Tabs from './tabs/Tabs';
import Home from './pages/home/Home';
import Events from './pages/events/Events';
import Newsletter from './pages/newsletter/Newsletter';
import Contact from './pages/contact/Contact';
import Donate from './pages/donate/Donate';

function App() {
  return (
    <div className='App'>
      <Tabs>
        <Home key='Home' />
        <Events key='Events' />
        <Newsletter key='Newsletter' />
        <Contact key='Contact Us' />
        <Donate key='Donate' />
      </Tabs>
    </div>
  );
}

export default App;
