import React, { useState, useMemo } from 'react';
import './Home.css';
import axios from 'axios';
import backend from '../../backend.json';
import { validateEmail, recaptchaToken } from '../../helpers';

const contactUrl = backend.contactBaseUrl + backend.subscribePath;

function Home(props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [inFlight, setInFlight] = useState(false);

    const validEmail = useMemo(() => validateEmail(email), [email]);

    return (
        <>
            <h2>Future of Orthodoxy</h2>
            <diV className='HomeIntroContainer'>
                <diV className='HomeIntroLogo' />
                <div className='HomeIntroText'>
                    <p>
                        It is with great joy that we announce the formation of Future of Orthodoxy and announce the inaugural issue of our newsletter.
                        Future of Orthodoxy is a youth organization representing the next generation of Orthodox Christians.
                        We organize events and publish our ideas and views in the form of a newsletter.
                    </p>
                </div>
            </diV>
            <p>
                Please stay in touch with us by subscribing to our email list below!
            </p>

            <h3>Our Mission</h3>
            <p>
                Our mission is twofold.
                The first is to strengthen the Orthodox youth by bringing them together for events, charities, and more.
                The second is to give Orthodox youth a forum where they can connect with other Orthodox youth and adults in the form of a newsletter.
            </p>
            <p>
                We ask for prayers and support that this humble undertaking will take flight and become a beacon of light uniting the future of orthodoxy with the teaching of the Apostles and the dogmas of the Fathers.
            </p>

            <h3>Subscribe to our Email List</h3>
            <p>
                Subscribe to our email mailing list below.
                We send out previews of our newsletters as well as any special events we are holding.
                This is our way to connect with you, not to advertise.
                We will never send you spam.
            </p>

            <p>Please enter a valid email address below</p>
            {error ? <p>An error occurred while contacting Future of Orthodoxy</p> : <></>}
            <div className='HomeSubscribeContainer'>
                <input onChange={event => setEmail(event.target.value)} value={email} placeholder={'Email'} />

                {validEmail ? <button
                    className='g-recaptcha'
                    disabled={inFlight}
                    onClick={() => {
                        setError(false);
                        setInFlight(true);
                        recaptchaToken()
                            .then(token => {
                                axios.post(contactUrl, { token, email })
                                    .then(data => {
                                        setEmail('');
                                        setError(false);
                                    })
                                    .catch(err => {
                                        setError(true);
                                        console.error(err);
                                    })
                                    .finally(() => {
                                        setInFlight(false);
                                    })
                            });
                    }}
                >
                    Subscribe
            </button> : <></>}
            </div>
        </>
    );
}

export default Home;