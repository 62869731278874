import React, { useState } from 'react';
import './Tabs.css';

function Tabs(props) {
    const {
        children
    } = props;

    const [tabIndex, setTabIndex] = useState(0);

    console.log(children[tabIndex]);

    return (
        <div className='TabsContainer'>
            <div className='TabsHeader'>
                <p className='TabsHeaderTopText'>Welcome to <span className='TabsHeaderTopTextEmphasis'>Future of Orthodoxy</span></p>
                <div className='TabsBannerContainer'>
                    {children.map((child, index) => (
                        <label
                            key={child.key}
                            className={'TabsBannerTab ' + (index === tabIndex ? 'TabsBannerActiveTab' : 'TabsBannerInactiveTab')}
                            onClick={() => setTabIndex(index)}
                        >
                            {child.key}
                        </label>
                    ))}
                </div>
            </div>
            <div className='TabsBody'>
                <div className='TabsBodyContent'>
                    {React.cloneElement(children[tabIndex], { setTabIndex })}
                </div>
            </div>
        </div>
    );
}

export default Tabs;